import { ref, watch, computed, onMounted } from '@vue/composition-api'
import axios from "@axios";
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSurveysList() {
  // Use toast
  const toast = useToast()

  const refSurveyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "title", sortable: true },
    { key: "category", sortable: true },
    { key: "type", sortable: true },
    { key: "reviewer", sortable: true },
    { key: "reviewee", sortable: true },
    { key: "engagement", sortable: true },
    { key: "isSubmitted", label: "Submitted", sortable: true },
    // { key: 'location', sortable: true },
    // { key: 'advisor', label: 'Advisor', sortable: true },
    // { key: 'isActive', label: 'Active', sortable: true },

    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
    // { key: 'actions' },
  ];
  const limit = ref(10)
  const totalSurveys = ref(0)
  const page = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('updatedAt')
  const sortOrder = ref('desc')
  const typeFilter = ref(null)
  const reviewerFilter = ref(null)
  const revieweeFilter = ref(null)
  const statusFilter = ref(null)
  const types = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refSurveyListTable.value ? refSurveyListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalSurveys.value,
    }
  })

  const refetchData = () => {
    refSurveyListTable.value.refresh()
  }

  watch([page, limit, searchQuery, typeFilter, reviewerFilter, revieweeFilter, statusFilter], () => {
    refetchData()
  })

  const fetchSurveys = (ctx, callback) => {
    store
      .dispatch('app-survey/fetchSurveys', {
        q: searchQuery.value,
        limit: limit.value,
        page: page.value,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
        type: typeFilter.value,
        reviewer: reviewerFilter.value,
        reviewee: revieweeFilter.value,
        isSubmitted: statusFilter.value === 'submitted' ? true :  statusFilter.value === 'pending' ? false: null,
      })
      .then(response => {
        const { surveys, total } = response.data
        callback(surveys)
        totalSurveys.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching surveys list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSurveyTypeVariant = (type, category) => {
    if (type === 'selfEval') return 'light-secondary'

    const categoryColors = {
      'AP/P': 'success',
      'PM/SPM': 'info',
      'Junior Staff': 'warning',
      'External': 'danger',
    }

    const typeVariants = {
      type1: '',
      type2: 'light-',
    }

    const categoryColor = categoryColors[category] || 'primary'
    const typeVariant = typeVariants[type] || ''

    return `${typeVariant}${categoryColor}`
  }

  const resolveSurveyTypeIcon = type => {
    if (type === 'type1') return 'FolderIcon'
    if (type === 'type2') return "DatabaseIcon";
    if (type === 'selfEval') return 'SmileIcon'
    return 'FolderIcon'
  }

  const resolveSurveyTypeText = (type, category) => {
    if (type === "selfEval") return "Self Eval";
    if (types.value && types.value[category]) {
      return types.value[category][type] || 'Evaluation'
    }
    return 'Evaluation'
  }

  const resolveDepartmentVariant = department => {
    if (department === 'advisors') return 'light-info'
    if (department === 'implement') return 'light-warning'
    if (department === 'operations') return 'light-secondary'
    if (department === 'external') return 'light-danger'
    return 'primary'
  }

  onMounted(() => {
    axios.get('/questionnaires/types')
      .then(response => {
        types.value = response.data
      })
      .catch(error => console.error('Error fetching type names:', error))
  })

  return {
    fetchSurveys,
    tableColumns,
    limit,
    page,
    totalSurveys,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    sortOrder,
    refSurveyListTable,

    resolveSurveyTypeVariant,
    resolveSurveyTypeIcon,
    resolveSurveyTypeText,
    resolveDepartmentVariant,
    refetchData,

    // Extra Filters
    typeFilter,
    reviewerFilter,
    revieweeFilter,
    statusFilter,
  }
}
